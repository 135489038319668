import React, {
  BaseSyntheticEvent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Helmet } from 'react-helmet'
import { PageProps, navigate } from 'gatsby'
import VisibilitySensor from 'react-visibility-sensor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

// Models | Types
import { ChapterModel } from '../../../models/Chapter.type'

// Components
import ListNav from '../../molecules/ListNav'
import DropdownSelect from '../../atoms/Dropdown'
import DilanButton from '../../atoms/DilanButton'
import CommentArea from '../../organisms/CommentArea'

// Config
import { chapter } from '../../../config/localization'

// Templates
import PageTemplate from '../PageTemplate'

// Context
import UserContext from '../../../context/User/User.context'
import LocalizationContext from '../../../context/Localization/Localization.context'

// GraphQL
import { getGraphChapters, getGraphSeries } from '../../../graphQL'

// Utils
import { getFont } from '../../../utils/utils'

// Const
import {
  READER_PAGE,
  READING_MODE,
  USER_TOKEN,
} from '../../../config/constants/localStorage'

// Style
import {
  navButton,
  navButtonTop,
  navButtonPrev,
  navButtonNext,
  navButtonBottom,
  footBar,
  pageText,
  inlineIcon,
  imageBlock,
  blueWrapper,
  donationBut,
  yellowWrapper,
  dropdownBlock,
  imageContainer,
  hiddenContainer,
} from './styles.module.scss'

const { ENDPOINT } = process.env

const ChapterTemplate: React.FC<PageProps<any, ChapterModel>> = ({
  pageContext: { id, number, series, pages, title, description },
}) => {
  const { language } = useContext(LocalizationContext)
  const { user, updateUser } = useContext(UserContext)

  const [localization, setLocalization] = useState(chapter.Default)

  const [page, setPage] = useState(1)
  const [loaded, setLoaded] = useState(0)
  const [sensor, setSensor] = useState(false)
  const [readerPage, setReaderPage] = useState(0)
  const [navVisible, setNavVisible] = useState(true)
  const [endCoordinates, setEndCoordinates] = useState([0, 0])
  const [startCoordinates, setStartCoordinates] = useState([0, 0])
  const [mode, setMode] = useState<'PageMode' | 'ListMode'>('ListMode')

  const allSeries = getGraphSeries()
  const seriesOptions = allSeries.map((serie) => {
    return { text: serie?.name[language], value: serie?.prefix }
  })

  const allChapters = getGraphChapters()
  const chapterOptions = allChapters
    .filter((chapter) => chapter.published && chapter?.series?.id == series.id)
    .sort((a, b) => a?.number - b?.number)
    .map((chapter) => {
      return {
        text: `${
          chapter?.number < 10 ? `0${chapter.number}` : chapter.number
        } ${chapter?.title[language]}`,
        value: String(chapter.number),
      }
    })

  const pageOptions: { text: string; value: string }[] = []
  for (let index = 0; index < pages; index++) {
    pageOptions.push({
      text: `${index + 1 < 10 ? `0${index + 1}` : index + 1}`,
      value: String(index + 1),
    })
  }

  const modeOptions = [
    { text: localization.pageMode, value: 'PageMode' },
    { text: localization.listMode, value: 'ListMode' },
  ]

  const chapterTitle = `${number < 10 ? `0${number}` : number} ${
    title[language]
  }`
  const seriesTitle = series.name[language]

  const curIndex = chapterOptions.findIndex(
    (chapter) => chapter.value === String(number)
  )

  const nextPage = () => {
    if (mode === 'PageMode') {
      if (page < pages) {
        setPage(page + 1)
      } else {
        nextChapter()
      }
    }
  }

  const nextChapter = () => {
    const next = chapterOptions.findIndex(
      (chapter) => chapter.value === String(number)
    )
    if (next < chapterOptions.length - 1) {
      const nextChapter = chapterOptions[next + 1]
      navigate(`/reader/${series.prefix}${nextChapter.value}`)
    } else {
      navigate(`/reader/endofseries/${series.short}`)
    }
  }
  const prevPage = () => {
    if (mode === 'PageMode') {
      if (page > 1) {
        setPage(page - 1)
      } else {
        prevChapter()
      }
    }
  }
  const prevChapter = () => {
    const prev = chapterOptions.findIndex(
      (chapter) => chapter.value === String(number)
    )
    if (prev > 0) {
      const nextChapter = chapterOptions[prev - 1]
      if (mode === 'PageMode') {
        localStorage.setItem(READER_PAGE, '1000')
      }
      navigate(`/reader/${series.prefix}${nextChapter.value}`)
    }
  }

  const checkKeyPress = (event) => {
    if (mode === 'PageMode') {
      if (event.key === 'ArrowRight') {
        nextPage()
      }
      if (event.key === 'ArrowLeft') {
        prevPage()
      }
    }
  }

  const scrollToPage = (num: number) => {
    const yOffset = -200
    const element = document.getElementById(`page_${num}`)
    const y =
      (element as HTMLElement).getBoundingClientRect().top +
      window.pageYOffset +
      yOffset

    window.scrollTo({ top: y, behavior: 'smooth' })
  }

  useEffect(() => {
    let readingMode = localStorage.getItem(READING_MODE)
    const localPage = Number(localStorage.getItem(READER_PAGE))
    setReaderPage(localPage)
    if (readingMode === 'PageMode' || readingMode === 'ListMode') {
      setMode(readingMode as 'PageMode' | 'ListMode')
    } else {
      localStorage.setItem(READING_MODE, 'PageMode')
      readingMode = 'PageMode'
    }

    updateUser({ ...user, lastChapter: String(id) })
  }, [])

  useEffect(() => {
    if (loaded === pages) {
      if (mode === 'PageMode') {
        if (readerPage > 100) {
          localStorage.setItem(READER_PAGE, '0')
          setPage(pages)
        }
        if (readerPage > 0 && readerPage <= pages) {
          setPage(readerPage)
        }
      }

      if (mode === 'ListMode') {
        if (readerPage > 100) {
          localStorage.setItem(READER_PAGE, '0')
          scrollToPage(pages)
          setPage(pages)
        }
        if (readerPage > 0 && readerPage <= pages) {
          scrollToPage(readerPage)
          setPage(readerPage)
        }
      }
      setTimeout(() => {
        setSensor(true)
      }, 1000)
    }
  }, [loaded])

  useEffect(() => {
    const newLocalization = chapter[language]
    if (typeof newLocalization !== 'undefined') {
      setLocalization(newLocalization)
    }
  }, [language])

  useEffect(() => {
    const token = localStorage.getItem(USER_TOKEN)
    if (token) {
      /* Update user page */
      if (page <= pages) {
        navigator.sendBeacon(
          `${ENDPOINT}/user/page`,
          JSON.stringify({ token, page, id })
        )
      }
    }
    document.addEventListener('keydown', checkKeyPress)
    if (mode === 'PageMode') {
      window.scrollTo(0, 0)
    }
    return () => {
      document.removeEventListener('keydown', checkKeyPress)
    }
  }, [page])

  return (
    <PageTemplate>
      <Helmet>
        <title>
          {language === 'ESP' ? 'Capítulo ' : 'Chapter '}
          {`${number}: ${title[language]}`}
        </title>
        <meta
          name="description"
          content={description ? description[language] : ''}
        />
        <meta
          name="keywords"
          content={
            language == 'ESP'
              ? 'Dilan Covak, webcomic, comic, comic gratis, fantasia, magia, espadas, peleas, Dilan, blanco y negro, homepage, lector'
              : 'Dilan Covak, webcomic, comic, free comic, fantasy, magic, swords, fights, Dilan, black and white, homepage, reader'
          }
        />
        <meta name="author" content="Daniel Penagos" />
        <meta name="copyright" content="Daniel Penagos" />
      </Helmet>
      <div className={`block-wrapper ${yellowWrapper}`}>
        <div className={`container ${dropdownBlock}`}>
          <div
            className={getFont('font-patua')}
            style={{
              minWidth: '250px',
              position: 'relative',
              textAlign: 'left',
            }}
          >
            <DropdownSelect
              text={seriesTitle}
              type="yellow"
              options={seriesOptions}
              handleClick={(value) => {
                localStorage.setItem(READER_PAGE, '0')
                navigate(`/reader/${value}1`)
              }}
              maxHeight="255px"
            />
          </div>
          <div
            className={getFont('font-patua')}
            style={{
              minWidth: '250px',
              position: 'relative',
              textAlign: 'left',
            }}
          >
            <DropdownSelect
              text={chapterTitle}
              type="yellow"
              options={chapterOptions}
              handleClick={(value) => {
                localStorage.setItem(READER_PAGE, '0')
                navigate(`/reader/${series.prefix}${value}`)
              }}
              maxHeight="255px"
            />
          </div>
          <div
            className={getFont('font-patua')}
            style={{
              width: '200px',
              float: 'right',
              position: 'relative',
              textAlign: 'left',
            }}
          >
            <DropdownSelect
              text={
                mode === 'PageMode'
                  ? localization.pageMode
                  : localization.listMode
              }
              type="yellow"
              options={modeOptions}
              handleClick={(value) => {
                if (value === 'PageMode' || value === 'ListMode') {
                  setMode(value as 'PageMode' | 'ListMode')
                  localStorage.setItem(READING_MODE, value)
                }
              }}
              maxHeight="255px"
            />
          </div>
          {mode === 'PageMode' && (
            <div
              className={getFont('font-patua')}
              style={{
                width: '90px',
                float: 'right',
                position: 'relative',
                textAlign: 'left',
              }}
            >
              <DropdownSelect
                text={page < 10 ? `0${page}` : String(page)}
                type="yellow"
                options={pageOptions}
                handleClick={(value) => {
                  setPage(Number(value))
                }}
                maxHeight="255px"
              />
            </div>
          )}
        </div>
      </div>
      <div className={`block-wrapper ${blueWrapper}`}>
        <div className={`container ${imageBlock}`}>
          {mode === 'ListMode' && navVisible && (
            <ListNav
              setPage={setPage}
              scrollToPage={scrollToPage}
              page={page}
              pages={pages}
            />
          )}
          {pageOptions.map((pg) => {
            return (
              <div
                key={pg.text}
                id={`page_${pg.value}`}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  if (mode === 'PageMode') {
                    var rect = event.currentTarget.getBoundingClientRect()
                    var x = event.clientX - rect.left
                    if (x < 50) {
                      prevPage()
                    } else {
                      nextPage()
                    }
                  }
                }}
                onTouchStart={(event) => {
                  setStartCoordinates([
                    event.touches[0].clientX,
                    event.touches[0].clientY,
                  ])
                }}
                onTouchMove={(event) => {
                  setEndCoordinates([
                    event.touches[0].clientX,
                    event.touches[0].clientY,
                  ])
                }}
                onTouchEnd={() => {
                  if (startCoordinates[0] - endCoordinates[0] < -50) {
                    prevPage()
                  } else if (startCoordinates[0] - endCoordinates[0] > 50) {
                    nextPage()
                  }
                }}
                className={`${imageContainer} ${
                  pg.value !== String(page) && mode === 'PageMode'
                    ? `${hiddenContainer}`
                    : ''
                }`}
              >
                <VisibilitySensor
                  onChange={(isVisible) => {
                    if (Number(pg.value) < pages) {
                      setNavVisible(true)
                    }
                    if (isVisible && sensor) {
                      setPage(Number(pg.value))
                    }
                  }}
                  partialVisibility
                  offset={{ top: 220, bottom: 220 }}
                >
                  <img
                    className="w-full"
                    src={`https://dilancovak.com/.img/chapters/${
                      series.prefix
                    }${number}/${language.toLowerCase()}_pg${pg.value}.jpg`}
                    alt={`Series ${series.name.ENG} - Chapter ${number} - Page ${pg.value} - Language ${language}`}
                    onLoad={() => {
                      setLoaded(loaded + 1)
                    }}
                    onError={(e: BaseSyntheticEvent) => {
                      console.log(e.target)
                      e.target.src =
                        'https://dilancovak.com/.img/system/PageError.jpg'
                      e.target.style =
                        'border-radius: 20px;box-sizing: border-box;margin-left: 8px;width: calc(100% - 16px);'
                    }}
                  />
                </VisibilitySensor>
              </div>
            )
          })}
          <div className={`${imageContainer} ${footBar}`}>
            <a
              href="https://www.paypal.com/paypalme/dilancovak"
              target="_blank"
            >
              <DilanButton
                type="primary"
                handleClick={() => {
                  null
                }}
                className={`${donationBut} ${getFont('font-lato')}`}
              >
                {localization.donate}
              </DilanButton>
            </a>
            {mode === 'PageMode' && (
              <div className={`${pageText} ${getFont('font-lato')}`}>
                {localization.page}: {page}/{pages}
              </div>
            )}
            {mode === 'ListMode' && (
              <div>
                {curIndex > 0 && (
                  <DilanButton
                    type="simple"
                    handleClick={() => {
                      prevChapter()
                    }}
                    className={getFont('font-lato')}
                  >
                    <FontAwesomeIcon
                      className={inlineIcon}
                      icon={faChevronLeft}
                    />
                    {localization.previous}
                  </DilanButton>
                )}
                <DilanButton
                  type="simple"
                  handleClick={() => {
                    nextChapter()
                  }}
                  className={getFont('font-lato')}
                >
                  {localization.next}
                  <FontAwesomeIcon
                    className={inlineIcon}
                    icon={faChevronRight}
                  />
                </DilanButton>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={`block-wrapper ${yellowWrapper}`}>
        <div className={'container'}>
          <div className={imageContainer}>
            <VisibilitySensor
              onChange={(isVisible) => {
                if (isVisible) {
                  setNavVisible(false)
                }
              }}
              partialVisibility
              offset={{ top: 220 }}
            >
              <CommentArea
                id={id}
                localization={{
                  comments: localization.comments,
                  new_comment: localization.default,
                  send: localization.send,
                }}
              />
            </VisibilitySensor>
          </div>
        </div>
      </div>
    </PageTemplate>
  )
}

export default ChapterTemplate
